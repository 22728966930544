<button (click)="facetToggle(field)" [class]="activeFacet === field ? 'border-tertiary-100' : 'border-transparent'" class="relative z-10 w-full text-sm font-bold rounded-md border-2 hover:border-tertiary-100 transition-colors duration-200 group">
  <span [class]="activeFacet === field ? 'shadow-none' : ''" class="tray w-full h-[46px] items-center bg-white rounded shadow-inset-md group-hover:shadow-none">
    @if (icon) {
      <span [class]="activeFacet === field ? 'bg-tertiary-100' : ''" class="flex justify-center items-center min-w-0 h-[20px] xl:h-[30px] rounded-[3px] bg-primary-300 group-hover:bg-tertiary-100 transition-colors duration-200">
        <facet-icon [icon]="icon" fill="white"></facet-icon>
      </span>
    }
    @if (selected) {
      <span class="min-w-0 inline-block text-left">
        <span class="w-full inline-block truncate text-xs leading-3">{{defaultLabel}}</span>
        <span class="flex leading-4 font-normal">
          <span class="inline-block truncate">{{selected[0].FacetLabel}}</span>
          @if (selected.length > 1) {
            <span class="inline-block ml-1 whitespace-nowrap">+ {{selected.length - 1}}</span>
          }
        </span>
      </span>
    } @else {
      <span class="min-w-0 inline-block truncate text-left">{{defaultLabel}}</span>
    }
    <span [class]="activeFacet === field ? 'text-tertiary-100' : ''" class="min-w-0 flex justify-end group-hover:text-tertiary-100 transition-colors duration-200">
      <i [ngClass]="activeFacet === field ? 'rotate-180' : 'rotate-0'"
      class="relative inline-block -top-px ml-2 transform transition-transform duration-200">
        <icon-chevron></icon-chevron>
      </i>
    </span>
  </span>
</button>
<div [class]="{'active': activeFacet !== null, 'z-[5]': activeFacet === field }" class="dropdown-container absolute left-0 w-full top-1/2 justify-center">
  <div [class]="activeFacet === field ? 'active' : ''" class="dropdown w-full p-8 bg-off-white rounded-sm shadow-lg min-w-0">
    @if (options$ | async; as options) {
      <div class="flex justify-center">
        <div class="relative min-w-0 px-10 xl:px-0">
          <button id="date-custom-prev" class="btn-slider z-10 absolute top-1/2 -translate-y-1/2 -left-4">
            <span class="block py-5 px-3 bg-primary-300">
              <i class="text-white">
                <icon-chevron [width]="16" [class]="'rotate-90'"></icon-chevron>
              </i>
            </span>
          </button>
          <swiper-container swiperElement init="false" [config]="swiperConfig">
            @for (year of options; track $index; let isFirst = $first, isLast = $last) {
              <swiper-slide [class]="!isLast ? 'mr-6' : ''" class="w-[260px]">
                <div class="mb-3 text-center font-bold">{{year.year}}</div>
                <div class="grid grid-cols-4 gap-1">
                  @for (month of year.months; track $index) {
                    <button (click)="optionToggle(month)" [disabled]="!month.available && !month.selected" [class]="month.selected ? 'bg-tertiary-200 font-bold' : month.available ? 'bg-true-gray-200 hover:bg-tertiary-100' : 'bg-true-gray-200 opacity-40 line-through'" class="group block p-0 w-full h-12 flex justify-center items-center rounded-sm text-sm">
                      <span [class]="month.selected
                        ? 'text-white' 
                        : month.available 
                          ? 'group-hover:text-white' 
                          : ''">
                        {{ month.label }}
                      </span>
                    </button>
                  }
                </div>
              </swiper-slide>
            }
          </swiper-container>
          <button id="date-custom-next" class="btn-slider z-10 absolute top-1/2 -translate-y-1/2 -right-4">
            <span class="block py-5 px-3 bg-primary-300">
              <i class="text-white">
                <icon-chevron [width]="16" [class]="'-rotate-90'"></icon-chevron>
              </i>
            </span>
          </button>
        </div>
      </div>
    }
    
    <hr class="h-0.5 my-4 bg-light-gray">
    <div class="flex justify-between">
      <button (click)="clearFilters()" [disabled]="!selected" [class]="!selected ? 'cursor-not-allowed' : 'hover:bg-gray-alt-100'" class="py-3 px-6 rounded bg-gray-alt-200  border-2 border-gray-alt-100 text-white shadow-lg">
        <span class="mr-2.5 uppercase font-bold text-sm">clear selection</span>
        <icon-close [height]="12" class="relative -top-px"></icon-close>
      </button>
      @if (actionLabel) {
        <button (click)="actionClick()" class="py-3 px-6 rounded bg-primary-200 hover:bg-primary-300 border-2 border-primary-100 text-white shadow-lg">
          <icon-search [fill]="'white'" class="relative -top-px"></icon-search>
          <span class="ml-2.5 uppercase font-bold text-sm">{{actionLabel}}</span>
        </button>
      }
    </div>
  </div>
</div>