import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChildren,
  QueryList,
  ElementRef,
} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {SiteIdService} from '@app/services';
import {ChevronIcon} from '@app/icons/chevron.icon';
import {CloseIcon} from '@app/icons/close.icon';
import {ArrowIcon, CaretIcon, SearchIcon} from '@app/icons';
import {CommonModule} from '@angular/common';
import {TruncatePipe} from '@app/pipes/truncate.pipe';
import {FacetIconComponent} from '@app/icons/facet-icon.component';
import {monthLabels} from '../dates';

@Component({
  standalone: true,
  selector: 'facet-date-accordion',
  imports: [
    ChevronIcon,
    CloseIcon,
    ArrowIcon,
    SearchIcon,
    CommonModule,
    TruncatePipe,
    FacetIconComponent,
    CaretIcon,
  ],
  templateUrl: './facet-date-accordion.component.html',
  styleUrls: ['./facet-date-accordion.component.css'],
})
export class FacetDateAccordionComponent {
  @Input({required: true})
  get activeFacet(): string | null {
    return this._activeFacet;
  }
  set activeFacet(activeFacet: string | null) {
    this._activeFacet = activeFacet;

    if (activeFacet === this.field) {
      const availableIdx = this.options$
        .getValue()
        .findIndex(option => option.available);
      this.activeYearIdx = availableIdx > -1 ? availableIdx : 0;
    }
  }
  private _activeFacet: string | null = null;

  @Input({required: true}) field = '';
  @Input({required: true}) defaultLabel = '';
  @Input() icon = '';
  // footer action buttons
  @Input() nextFacet: {label: string; field: string} | null = null;
  @Input() actionLabel: string | null = null;

  @Input({required: true})
  get selected():
    | {FacetName: string; FacetValue: string; FacetLabel: string}[]
    | null {
    return this._selected;
  }
  set selected(selected: {FacetName: string; FacetValue: string}[] | null) {
    let labeled = null;
    if (selected) {
      labeled = selected.map(val => {
        return {
          ...val,
          FacetLabel: this.getLabel(val.FacetValue),
        };
      });
    }
    this._selected = labeled;
    this.updateOptions(this.options, labeled);
  }
  private _selected:
    | {FacetName: string; FacetValue: string; FacetLabel: string}[]
    | null = null;

  @Input({required: true})
  get options(): {FacetName: string; FacetValue: string; FacetLabel: string}[] {
    return this._options;
  }
  set options(options: {FacetName: string; FacetValue: string}[]) {
    const labeled = options.map(val => {
      return {
        ...val,
        FacetLabel: this.getLabel(val.FacetValue),
      };
    });
    this._options = labeled;
    this.updateOptions(labeled, this.selected);
  }
  private _options: {
    FacetName: string;
    FacetValue: string;
    FacetLabel: string;
  }[] = [];

  // ordered active options
  activeOptions: {
    facet: string;
    label: string;
  }[] = [];

  options$ = new BehaviorSubject<
    {
      available: boolean;
      selected: boolean;
      year: string;
      months: Array<{
        available: boolean;
        selected: boolean;
        facet: string;
        label: string;
      }>;
    }[]
  >([]);

  siteID;

  activeYearIdx: number | null = null;

  // outputs
  @Output() toggleFacet = new EventEmitter<string>();
  @Output() toggleOption = new EventEmitter<{
    FacetName: string;
    FacetValue: string;
    FacetLabel: string;
    selected: boolean;
  }>();
  @Output() clearFacet = new EventEmitter();
  @Output() performAction = new EventEmitter();

  @ViewChildren('tabBtn') tabBtns!: QueryList<ElementRef<HTMLButtonElement>>;

  constructor(private siteIdService: SiteIdService) {
    this.siteID = this.siteIdService.site.siteID;
  }

  facetToggle(field: string) {
    this.toggleFacet.emit(field);
  }

  optionToggle(facetOption: {facet: string; label: string; selected: boolean}) {
    const option = {
      FacetName: this.field,
      FacetValue: facetOption.facet,
      FacetLabel: facetOption.label,
      selected: facetOption.selected,
    };
    this.toggleOption.emit(option);
  }

  clearFilters() {
    this.clearFacet.emit();
  }

  actionClick() {
    this.performAction.emit();
  }

  onKeydown(event: KeyboardEvent) {
    const availableLength = 4;
    const maxIdx = availableLength > 0 ? availableLength - 1 : 0;
    let matchKey = false;
    if (this.activeYearIdx !== null) {
      switch (event.key) {
        case 'ArrowLeft':
          this.activeYearIdx === 0
            ? (this.activeYearIdx = maxIdx)
            : this.activeYearIdx--;
          matchKey = true;
          break;
        case 'ArrowRight':
          this.activeYearIdx === maxIdx
            ? (this.activeYearIdx = 0)
            : this.activeYearIdx++;
          matchKey = true;
          break;
        case 'Home':
          this.activeYearIdx = 0;
          matchKey = true;
          break;
        case 'End':
          this.activeYearIdx = maxIdx;
          matchKey = true;
          break;
        default:
          break;
      }
      if (matchKey) {
        event.stopPropagation();
        event.preventDefault();
      }
      this.tabBtns.get(this.activeYearIdx)?.nativeElement.focus();
    }
  }

  toggleYear(idx: number) {
    this.activeYearIdx = idx;
  }

  private getLabel(option: string): string {
    const dateParts = option.split('-'); // "2024-12" => [2024, 12]
    return `${monthLabels[parseInt(dateParts[1]) - 1]} ${dateParts[0]}`;
  }

  private updateOptions(
    options: {FacetName: string; FacetValue: string; FacetLabel: string}[],
    selectedOptions:
      | {FacetName: string; FacetValue: string; FacetLabel: string}[]
      | null
  ) {
    // month options
    const yearsToShow = 4;
    const startYear = new Date().getFullYear();
    const endYear = startYear + yearsToShow;

    const withSelected: Array<{
      available: boolean;
      selected: boolean;
      year: string;
      months: Array<{
        available: boolean;
        selected: boolean;
        facet: string;
        label: string;
      }>;
    }> = [];

    for (let i = 0; i < endYear - startYear; i++) {
      const yearStr = (startYear + i).toString();
      withSelected[i] = {
        available: false,
        selected: false,
        year: yearStr,
        months: [],
      };
      monthLabels.forEach((month, j) => {
        const labelStr = `${month} ${yearStr}`;
        const selected =
          selectedOptions?.some(option => option.FacetLabel === labelStr) ??
          false;
        const facetMatch = options.find(facet => facet.FacetLabel === labelStr)
          ?.FacetValue;
        let facet: string;
        if (facetMatch) {
          facet = facetMatch;
        } else {
          facet = `${yearStr}-${j + 1 < 10 ? '0' : ''}${j + 1}`; //"2024-08"
        }
        const available = facetMatch !== undefined;
        if (available) withSelected[i].available = true;
        if (selected) withSelected[i].selected = true;
        withSelected[i].months.push({
          available,
          selected,
          facet,
          label: month,
        });
      });
    }

    // update options$
    this.options$.next(withSelected);
  }
}
