import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import {FormControl, ReactiveFormsModule, FormGroup} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map, startWith, combineLatestWith} from 'rxjs/operators';
import {SiteIdService} from '@app/services';
import {ChevronIcon} from '@app/icons/chevron.icon';
import {CloseIcon} from '@app/icons/close.icon';
import {ArrowIcon, SearchIcon} from '@app/icons';
import {CommonModule} from '@angular/common';
import {TruncatePipe} from '@app/pipes/truncate.pipe';
import {FacetIconComponent} from '@app/icons/facet-icon.component';

@Component({
  standalone: true,
  selector: 'facet-standard-wide',
  imports: [
    ChevronIcon,
    CloseIcon,
    ArrowIcon,
    SearchIcon,
    CommonModule,
    TruncatePipe,
    FacetIconComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './facet-standard-wide.component.html',
  styleUrls: ['./facet-standard-wide.component.css'],
})
export class FacetStandardWideComponent implements OnInit {
  @Input({required: true})
  get activeFacet(): string | null {
    return this._activeFacet;
  }
  set activeFacet(activeFacet: string | null) {
    this._activeFacet = activeFacet;
    if (activeFacet === this.field) {
      this.searchForm.setValue({
        search: '',
      });
    }
  }
  private _activeFacet: string | null = null;

  @Input({required: true}) field = '';
  @Input({required: true}) defaultLabel = '';
  @Input({required: true}) search = false;
  @Input() icon = '';

  @Input() labels?: {[key: string]: {label: string; order: number}};

  // footer action buttons
  @Input() nextFacet: {label: string; field: string} | null = null;
  @Input() actionLabel: string | null = null;

  @Input({required: true})
  get selected():
    | {FacetName: string; FacetValue: string; FacetLabel: string}[]
    | null {
    return this._selected;
  }
  set selected(selected: {FacetName: string; FacetValue: string}[] | null) {
    let labeled = null;
    if (selected) {
      labeled = selected.map(val => {
        const label = this.getLabel(val.FacetValue);
        return {
          ...val,
          FacetLabel: label,
        };
      });
    }
    this._selected = labeled;
    this.updateOptions(this.options, labeled);
  }
  private _selected:
    | {FacetName: string; FacetValue: string; FacetLabel: string}[]
    | null = null;

  @Input({required: true})
  get options(): {FacetName: string; FacetValue: string; FacetLabel: string}[] {
    return this._options;
  }
  set options(options: {FacetName: string; FacetValue: string}[]) {
    const labeled = options.map(val => {
      const label = this.getLabel(val.FacetValue);
      return {
        ...val,
        FacetLabel: label,
      };
    });
    this._options = labeled;
    this.updateOptions(labeled, this.selected);
  }
  private _options: {
    FacetName: string;
    FacetValue: string;
    FacetLabel: string;
  }[] = [];

  // ordered active options
  activeOptions: {
    facet: string;
    label: string;
  }[] = [];

  private unfilteredOptions$ = new BehaviorSubject<
    {
      selected: boolean;
      FacetName: string;
      FacetValue: string;
      FacetLabel: string;
    }[]
  >([]);

  filteredOptions$!: Observable<
    {
      selected: boolean;
      FacetName: string;
      FacetValue: string;
      FacetLabel: string;
    }[]
  >;

  // search form
  searchForm = new FormGroup({
    search: new FormControl(''),
  });

  get searchVal() {
    return this.searchForm.get('search')?.value;
  }

  siteID;

  @ViewChild('initFocus') initFocus!: ElementRef;

  // outputs
  @Output() toggleFacet = new EventEmitter<string>();
  @Output() toggleOption = new EventEmitter<{
    FacetName: string;
    FacetValue: string;
    FacetLabel: string;
    selected: boolean;
  }>();
  @Output() clearFacet = new EventEmitter();
  @Output() performAction = new EventEmitter();

  constructor(private siteIdService: SiteIdService) {
    this.siteID = this.siteIdService.site.siteID;
  }

  ngOnInit(): void {
    const searchStr$ = this.searchForm.controls.search.valueChanges.pipe(
      filter((searchStr): searchStr is string => searchStr !== null),
      startWith('')
    );

    this.filteredOptions$ = this.unfilteredOptions$.pipe(
      combineLatestWith(searchStr$),
      map(([unfilteredOptions, searchStr]) => {
        if (searchStr.length > 0) {
          return unfilteredOptions.filter(option =>
            option.FacetLabel.toLowerCase().startsWith(searchStr.toLowerCase())
          );
        }
        return unfilteredOptions;
      })
    );
  }

  clearSearch() {
    this.searchForm.setValue({
      search: '',
    });
    this.initFocus.nativeElement.focus();
  }

  facetToggle(field: string) {
    this.toggleFacet.emit(field);
  }

  optionToggle(facetOption: {
    FacetName: string;
    FacetValue: string;
    FacetLabel: string;
    selected: boolean;
  }) {
    this.toggleOption.emit(facetOption);
  }

  clearFilters() {
    this.clearFacet.emit();
  }

  actionClick() {
    this.performAction.emit();
  }

  private getLabel(option: string): string {
    return this.labels?.[option]?.label ?? option;
  }

  private updateOptions(
    options: {FacetName: string; FacetValue: string; FacetLabel: string}[],
    selectedOptions:
      | {FacetName: string; FacetValue: string; FacetLabel: string}[]
      | null
  ) {
    const combined = (
      selectedOptions ? options.concat(selectedOptions) : options
    )
      .filter(
        (option, idx, self) =>
          self.findIndex(
            selfOption => selfOption.FacetValue === option.FacetValue
          ) === idx
      )
      .map(option => {
        return {
          ...option,
          selected:
            selectedOptions?.some(
              val => val.FacetValue === option.FacetValue
            ) ?? false,
        };
      })
      .sort((a, b) => {
        if (
          this.labels &&
          this.labels?.[a.FacetValue]?.order !== undefined &&
          this.labels?.[b.FacetValue]?.order !== undefined
        ) {
          return (
            this.labels?.[a.FacetValue].order -
            this.labels?.[b.FacetValue].order
          );
        }
        return a.FacetLabel.localeCompare(b.FacetLabel);
      });
    // update options$
    this.unfilteredOptions$.next(combined);
  }
}
