<button (click)="facetToggle(field)" [attr.aria-expanded]="activeFacet === field" [attr.aria-controls]="'sect-' + field" [id]="'accordion-' + field" [class]="activeFacet === field ? 'border-tertiary-100' : 'border-transparent'" class="relative z-10 w-full text-sm font-bold rounded-md border-2 hover:border-tertiary-100 transition-colors duration-200 group">
  <span [class]="activeFacet === field ? 'shadow-none' : ''" class="tray w-full h-[46px] items-center bg-white rounded shadow-inset-md group-hover:shadow-none">
    @if (icon) {
      <span [class]="activeFacet === field ? 'bg-tertiary-100' : ''" class="flex justify-center items-center min-w-0 h-[30px] rounded-[3px] bg-primary-300 group-hover:bg-tertiary-100 transition-colors duration-200">
        <facet-icon [icon]="icon" fill="white"></facet-icon>
      </span>
    }
    @if (labeledSelected$ | async; as selected) {
      <span class="min-w-0 inline-block text-left">
        <span class="w-full inline-block truncate text-xs leading-3">{{defaultLabel}}</span>
        <span class="flex leading-4 font-normal">
          <span class="inline-block truncate">{{selected[0].FacetLabel}}</span>
          @if (selected.length > 1) {
            <span class="inline-block ml-1 whitespace-nowrap">+ {{selected.length - 1}}</span>
          }
        </span>
      </span>
    } @else {
      <span class="min-w-0 inline-block truncate text-left">{{defaultLabel}}</span>
    }
    <span [class]="activeFacet === field ? 'text-tertiary-100' : ''" class="min-w-0 flex justify-end group-hover:text-tertiary-100 transition-colors duration-200">
      <i [ngClass]="activeFacet === field ? 'rotate-180' : 'rotate-0'"
      class="relative inline-block -top-px ml-2 transform transition-transform duration-200">
        <icon-chevron></icon-chevron>
      </i>
    </span>
  </span>
</button>
<div [id]="'sect-' + field" role="region" [attr.aria-labelledby]="'accordion-' + field" class="bg-white">
  @if (activeFacet === field) {
    <div class="px-4 py-2">
      <div class="mb-4">
        <button (click)="clearFilters()" [disabled]="!selected"
          [ngClass]="selected ? 'bg-true-gray-700 cursor-pointer text-white' : 'bg-true-gray-400 cursor-not-allowed text-true-gray-200'"
          class="w-full py-3 rounded focus:outline-tertiary-200 text-xs font-bold">Clear Selection</button>
      </div>
      <div class="mb-2.5">
        <form [formGroup]="searchForm" class="relative">
          <input #initFocus formControlName="search" type="text" [ngClass]="searchVal && searchVal.length > 0 ? 'pr-14' : 'pr-3'"
            class="w-full pl-3 py-3 max-w-none bg-white text-xs text-black font-bold rounded shadow-inset-sm focus:outline-tertiary-200 placeholder:font-normal"
            placeholder="Search for a {{ defaultLabel }}...">
          @if (searchVal && searchVal.length > 0) {
          <div class="absolute right-0 top-0 bottom-0 flex justify-center">
            <button (click)="clearSearch()" aria-label="Clear Search"
              class="flex justify-center items-center px-4 rounded hover:bg-tertiary-200 hover:text-white focus:outline-none focus:bg-tertiary-200 focus:text-white text-black">
              <icon-close [height]="12"></icon-close>
            </button>
          </div>
          }
        </form>
      </div>
      <ol class="opt-out h-32 lg:h-52 overflow-y-auto">
        @if (filteredOptions$ | async; as filteredOptions) {
          @if (filteredOptions.length > 0) {
            @if (!searchForm.controls.search.value) {
              @if (selected) {
                <li class="mb-1.5">
                  <button (click)="clearFilters()" class="bg-true-gray-200 w-full group py-2 px-4 text-center text-xs rounded-sm hover:bg-tertiary-100 focus:outline-tertiary-200">
                    <span class="group-hover:text-white">Any {{defaultLabel}}
                    </span>
                  </button>
                </li>
              } @else {
                <li class="mb-1.5 bg-tertiary-100 font-bold py-2 px-4 text-center text-white text-xs rounded-sm">Any {{defaultLabel}}
                </li>
              }
            }
            @for (facetOption of filteredOptions; track $index) {
              <li class="mb-1.5">
                <button (click)="optionToggle(facetOption)"
                  [ngClass]="facetOption.selected ? 'bg-tertiary-100 font-bold' : 'bg-true-gray-200'"
                  class="w-full group py-2 px-4 text-center text-xs rounded-sm hover:bg-tertiary-100 focus:outline-tertiary-200">
                  <span [class]="facetOption.selected 
                        ? 'text-white' 
                        : 'group-hover:text-white'">
                    {{ facetOption.FacetLabel }}
                  </span>
                </button>
              </li>
              }
          } @else {
            <li class="my-3 text-center italic text-gray-400">
              No Matching Results
            </li>
          }
        }
      </ol>
    </div>
  }
</div>