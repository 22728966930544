import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import {FormControl, ReactiveFormsModule, FormGroup} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map, startWith, combineLatestWith} from 'rxjs/operators';
import {SearchService, SiteIdService} from '@app/services';
import {ChevronIcon} from '@app/icons/chevron.icon';
import {CloseIcon} from '@app/icons/close.icon';
import {ArrowIcon, SearchIcon} from '@app/icons';
import {AsyncPipe, CommonModule} from '@angular/common';
import {TruncatePipe} from '@app/pipes/truncate.pipe';
import {FacetIconComponent} from '@app/icons/facet-icon.component';

@Component({
  standalone: true,
  selector: 'facet-destination-wide',
  imports: [
    ChevronIcon,
    CloseIcon,
    ArrowIcon,
    SearchIcon,
    CommonModule,
    TruncatePipe,
    FacetIconComponent,
    ReactiveFormsModule,
    AsyncPipe,
  ],
  templateUrl: './facet-destination-wide.component.html',
  styleUrls: ['./facet-destination-wide.component.css'],
})
export class FacetDestinationWideComponent implements OnInit {
  @Input({required: true})
  get activeFacet(): string | null {
    return this._activeFacet;
  }
  set activeFacet(activeFacet: string | null) {
    this._activeFacet = activeFacet;
    if (activeFacet === this.field) {
      this.searchForm.setValue({
        search: '',
      });
    }
  }
  private _activeFacet: string | null = null;

  @Input({required: true}) field = '';
  @Input({required: true}) defaultLabel = '';
  @Input() icon = '';
  // footer action buttons
  @Input() nextFacet: {label: string; field: string} | null = null;
  @Input() actionLabel: string | null = null;

  @Input({required: true})
  get selected(): {FacetName: string; FacetValue: string}[] | null {
    return this._selected;
  }
  set selected(selected: {FacetName: string; FacetValue: string}[] | null) {
    this.selected$.next(selected);
    this._selected = selected;
    this.updateOptions(this.options, selected);
  }
  private _selected: {FacetName: string; FacetValue: string}[] | null = null;

  @Input({required: true})
  get options(): {FacetName: string; FacetValue: string}[] {
    return this._options;
  }
  set options(options: {FacetName: string; FacetValue: string}[]) {
    this._options = options;
    this.updateOptions(options, this.selected);
  }
  private _options: {FacetName: string; FacetValue: string}[] = [];

  // ordered active options
  activeOptions: {
    facet: string;
    label: string;
  }[] = [];

  private unfilteredOptions$ = new BehaviorSubject<
    {
      selected: boolean;
      FacetName: string;
      FacetValue: string;
    }[]
  >([]);

  filteredOptions$!: Observable<
    {
      selected: boolean;
      FacetName: string;
      FacetValue: string;
      FacetLabel: string;
    }[]
  >;

  private selected$ = new BehaviorSubject<
    {FacetName: string; FacetValue: string}[] | null
  >(null);
  labeledSelected$!: Observable<
    {FacetName: string; FacetValue: string; FacetLabel: string}[] | null
  >;

  // search form
  searchForm = new FormGroup({
    search: new FormControl(''),
  });

  get searchVal() {
    return this.searchForm.get('search')?.value;
  }

  siteID;

  @ViewChild('initFocus') initFocus!: ElementRef;

  // outputs
  @Output() toggleFacet = new EventEmitter<string>();
  @Output() toggleOption = new EventEmitter<{
    FacetName: string;
    FacetValue: string;
    FacetLabel: string;
    selected: boolean;
  }>();
  @Output() clearFacet = new EventEmitter();
  @Output() performAction = new EventEmitter();

  constructor(
    private siteIdService: SiteIdService,
    private searchService: SearchService
  ) {
    this.siteID = this.siteIdService.site.siteID;
  }

  ngOnInit(): void {
    const searchStr$ = this.searchForm.controls.search.valueChanges.pipe(
      filter((searchStr): searchStr is string => searchStr !== null),
      startWith('')
    );

    this.labeledSelected$ = this.selected$.pipe(
      combineLatestWith(this.searchService.destinationMaps$),
      map(([selected, destinationMaps]) => {
        if (!selected) return null;
        const labeled: {
          FacetName: string;
          FacetValue: string;
          FacetLabel: string;
        }[] = [];
        selected.forEach(option => {
          if (option.FacetName === 'dest_id') {
            const customDestLabel = destinationMaps.find(
              destMap => destMap.ID === parseInt(option.FacetValue)
            )?.Name;
            if (customDestLabel) {
              labeled.push({
                ...option,
                FacetLabel: customDestLabel,
              });
            }
          } else if (
            option.FacetName === 'europe' &&
            option.FacetValue === 'true'
          ) {
            labeled.push({
              ...option,
              FacetLabel: 'Europe',
            });
          }
        });
        return labeled;
      })
    );

    this.filteredOptions$ = this.unfilteredOptions$.pipe(
      combineLatestWith(this.searchService.destinationMaps$),
      map(([unfilteredOptions, destinationMaps]) => {
        const labeled: {
          selected: boolean;
          FacetName: string;
          FacetValue: string;
          FacetLabel: string;
        }[] = [];
        unfilteredOptions.forEach(option => {
          if (option.FacetName === 'dest_id') {
            const customDestLabel = destinationMaps.find(
              destMap => destMap.ID === parseInt(option.FacetValue)
            )?.Name;
            if (customDestLabel) {
              labeled.push({
                ...option,
                FacetLabel: customDestLabel,
              });
            }
          } else if (
            option.FacetName === 'europe' &&
            option.FacetValue === 'true'
          ) {
            labeled.push({
              ...option,
              FacetLabel: 'Europe',
            });
          }
        });
        return labeled.sort((a, b) => a.FacetLabel.localeCompare(b.FacetLabel));
      }),
      combineLatestWith(searchStr$),
      map(([unfilteredOptions, searchStr]) => {
        if (searchStr.length > 0) {
          return unfilteredOptions.filter(option =>
            option.FacetLabel.toLowerCase().startsWith(searchStr.toLowerCase())
          );
        }
        return unfilteredOptions;
      })
    );
  }

  clearSearch() {
    this.searchForm.setValue({
      search: '',
    });
    this.initFocus.nativeElement.focus();
  }

  facetToggle(field: string) {
    this.toggleFacet.emit(field);
  }

  optionToggle(facetOption: {
    FacetName: string;
    FacetValue: string;
    FacetLabel: string;
    selected: boolean;
  }) {
    this.toggleOption.emit(facetOption);
  }

  clearFilters() {
    this.clearFacet.emit();
  }

  actionClick() {
    this.performAction.emit();
  }

  private updateOptions(
    options: {FacetName: string; FacetValue: string}[],
    selectedOptions: {FacetName: string; FacetValue: string}[] | null
  ) {
    const combined = (
      selectedOptions ? options.concat(selectedOptions) : options
    )
      .filter(
        (option, idx, self) =>
          self.findIndex(
            selfOption => selfOption.FacetValue === option.FacetValue
          ) === idx
      )
      .map(option => {
        return {
          ...option,
          selected:
            selectedOptions?.some(
              val => val.FacetValue === option.FacetValue
            ) ?? false,
        };
      });
    // update options$
    this.unfilteredOptions$.next(combined);
  }
}
