<button (click)="facetToggle(field)" [attr.aria-expanded]="activeFacet === field" [attr.aria-controls]="'sect-' + field" [id]="'accordion-' + field" [class]="activeFacet === field ? 'border-tertiary-100' : 'border-transparent'" class="relative z-10 w-full text-sm font-bold rounded-md border-2 hover:border-tertiary-100 transition-colors duration-200 group">
  <span [class]="activeFacet === field ? 'shadow-none' : ''" class="tray w-full h-[46px] items-center bg-white rounded shadow-inset-md group-hover:shadow-none">
    @if (icon) {
      <span [class]="activeFacet === field ? 'bg-tertiary-100' : ''" class="flex justify-center items-center min-w-0 h-[30px] rounded-[3px] bg-primary-300 group-hover:bg-tertiary-100 transition-colors duration-200">
        <facet-icon [icon]="icon" fill="white"></facet-icon>
      </span>
    }
    @if (selected) {
      <span class="min-w-0 inline-block text-left">
        <span class="w-full inline-block truncate text-xs leading-3">{{defaultLabel}}</span>
        <span class="flex leading-4 font-normal">
          <span class="inline-block truncate">{{selected[0].FacetLabel}}</span>
          @if (selected.length > 1) {
            <span class="inline-block ml-1 whitespace-nowrap">+ {{selected.length - 1}}</span>
          }
        </span>
      </span>
    } @else {
      <span class="min-w-0 inline-block truncate text-left">{{defaultLabel}}</span>
    }
    <span [class]="activeFacet === field ? 'text-tertiary-100' : ''" class="min-w-0 flex justify-end group-hover:text-tertiary-100 transition-colors duration-200">
      <i [ngClass]="activeFacet === field ? 'rotate-180' : 'rotate-0'"
      class="relative inline-block -top-px ml-2 transform transition-transform duration-200">
        <icon-chevron></icon-chevron>
      </i>
    </span>
  </span>
</button>
<div [id]="'sect-' + field" role="region" [attr.aria-labelledby]="'accordion-' + field" class="bg-white">
  @if (activeFacet === field) {
    <div class="px-4 py-2">
      <div class="mb-4">
        <button (click)="clearFilters()" [disabled]="!selected"
          [ngClass]="selected ? 'bg-true-gray-700 cursor-pointer text-white' : 'bg-true-gray-400 cursor-not-allowed text-true-gray-200'"
          class="w-full py-3 rounded focus:outline-tertiary-100 text-xs font-bold">Clear Selection</button>
      </div>
      @if (options$ | async; as options) {
        <div role="tablist" aria-label="Depart Month" class="grid grid-rows-1 grid-cols-4 gap-1 mb-3">
          @for (year of options; track year.year; let i = $index) {
          <div class="relative">
            <button #tabBtn id="tab-{{i + 1}}" type="button" role="tab" (keydown)="onKeydown($event)"
              [attr.aria-selected]="activeYearIdx === i" attr.aria-controls="tabpanel-{{i + 1}}"
              [attr.tabindex]="activeYearIdx === i ? 0 : -1" (click)="toggleYear(i)"
              [disabled]="!year.available && !year.selected"
              [ngClass]="activeYearIdx === i ? 'shadow-xl opacity-100' : 'opacity-60'" class="w-full text-xs">
              <span
                [ngClass]="year.selected ? 'bg-tertiary-100 font-bold text-white' : year.available ? 'bg-true-gray-700 text-white' : 'opacity-40 bg-true-gray-700 text-white line-through'"
                class="block px-4 py-2 w-full rounded-sm">
                <span>
                  {{ year.year }}
                </span>
              </span>
            </button>
            @if (activeYearIdx === i) {
            <div class="absolute top-3/4 left-1/2 -translate-x-1/2">
              <i class="text-tertiary-100">
                <icon-caret></icon-caret>
              </i>
            </div>
            }
          </div>
          }
        </div>
        <div class="relative flex overflow-hidden">
          @for (year of options; track year.year; let j = $index) {
          <div id="tabpanel-{{j + 1}}" role="tabpanel" attr.aria-labelledby="tab-{{j + 1}}"
            [ngClass]="activeYearIdx === j ? 'active' : ''" class="month-panel flex-1 grid grid-rows-3 grid-cols-4 gap-1">
            @for (month of year.months; track month.label; let k = $index) {
            <div>
              <button (click)="optionToggle(month)" [disabled]="!month.available && !month.selected"
                [ngClass]="month.selected ? 'bg-tertiary-100 font-bold text-white' : month.available ? 'bg-true-gray-200' : 'bg-true-gray-200 opacity-40 line-through'"
                class="block w-full px-3.5 py-4 rounded text-xs">
                <span>
                  {{ month.label }}
                </span>
              </button>
            </div>
            }
          </div>
          }
        </div>
      }
    </div>
  }
</div>